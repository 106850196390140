@use "util" as u;

.footer {
  &__icons {
    justify-content: center;
    display: flex;
    gap: u.rem(30);
    list-style: none;
    margin-bottom: u.rem(20);
    cursor: pointer;
    li {
      color: var(--blue);
      border: u.rem(0.2) solid var(--pale-blue);
      padding: 5px 11px;
      border-radius: 50%;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: var(--blue);
      }
    }
  }
  &__copyright {
    text-align: center;
    margin-bottom: u.rem(20);
    color: var(--gray);
  }
}
