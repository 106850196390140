@use "util" as u;
.component {
  max-width: u.rem(650);
  @include u.breakpoint-down(medium) {
    margin: u.rem(20);
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // align-items: center;
    margin-top: u.rem(88);
    margin-bottom: u.rem(88);
    &__logo-box {
      margin-bottom: u.rem(57);
      align-self: center;
    }
    &__text-box {
      margin-bottom: u.rem(42);
      align-self: center;
      text-align: center;
    }
    &__main {
      margin-bottom: u.rem(24);
      font-weight: 300;
      font-size: u.rem(45);
      color: var(--gray);
      span {
        font-weight: 700;
        color: var(--very-dark-blue);
      }
    }
    &__sub {
      font-weight: 300;
      font-size: u.rem(18);
    }
    &__main {
      margin-bottom: u.rem(24);
    }
    &__search {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      gap: u.rem(20);
    }
    &__box {
      width: 100%;
    }
    &__input {
      padding: u.rem(18);
      border-radius: u.rem(30);
      color: var(--gray);
      border: 1px solid var(--pale-blue);
      width: 100%;
    }
    &__button {
      color: #fff;
      font-weight: 700;
      text-decoration: none;
      background-color: var(--blue);
      padding: u.rem(18);
      width: 45%;
      border-radius: u.rem(30);
      text-align: center;
      &:hover {
        background-color: var(--light-blue);
      }
    }
    &__error {
      display: none;
      margin-top: u.rem(5);
      margin-left: u.rem(32);
      color: var(--light-red);
    }
    &__error--shown {
      display: inline;
    }
  }

  .image-box {
    margin-bottom: u.rem(70);
    width: 100%;
    .illustration-dashboard {
      max-width: 100%;
    }
  }
}
@include u.breakpoint-down(small) {
  .component {
    .header {
      &__main {
        font-size: u.rem(30);
      }
      &__sub {
        font-weight: 300;
        font-size: u.rem(14);
      }
      &__search {
        flex-direction: column;
        align-items: center;
        gap: u.rem(10);
      }
      &__button {
        margin-top: u.rem(16);
        width: 100%;
      }
      &__error {
        margin-left: 0;
        text-align: center;
      }
    }
  }
}
